/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React, { Component, createRef, useContext } from "react";
import { graphql } from "gatsby";
import Glide from "@glidejs/glide";
import Img from "gatsby-image";
import Plx from "react-plx";
import { DocumentContext } from "~context/DocumentContext";
import Footer from "~components/Footer";
import Layout from "~components/Layout";
import LocaleLink from "~components/LocaleLink";
import NavClipped from "~components/NavClipped";
import SEO from "~components/SEO";
import SwiperCarousel from "~components/SwiperCarousel.jsx";
import { fancyWarning } from "~utils/helpers";

class ExclusivePageComponent extends Component {
  state = {
    authenticated: false,
    password: ``
  };

  carouselCount = 0;

  glides = [];

  glideRetryCount = 0;

  timeouts = [];

  componentDidMount() {
    if (window) {
      window.addEventListener(`keydown`, this.handleKeydown, false);
    }

    this.createCarousels();
  }

  componentWillUnmount() {
    this.clearTimeouts();

    if (window) {
      window.removeEventListener(`keydown`, this.handleKeydown, false);
    }
  }

  handleKeydown = e => {
    switch (e.keyCode) {
      case 13:
        if (this.state.password !== ``) {
          this.unlock();
        }

        break;

      default:
        break;
    }
  };

  onChange = e => {
    let { password } = this.state;

    password = e.target.value;

    this.setState({
      password
    });
  };

  unlock = () => {
    const { frontmatter } = this.props;
    const { password } = this.state;

    if (password === frontmatter.password) {
      this.setState({
        authenticated: true
      });

      if (this.carouselCount > 0) {
        setTimeout(() => {
          this.mountCarousels();
        }, 200);
      }
    }
  };

  //

  clearTimeouts = () => {
    this.timeouts.forEach(timeout => {
      clearTimeout(timeout);
    });
  };

  //

  createCarousels = () => {
    const { frontmatter } = this.props;

    if (!frontmatter.components || frontmatter.components.length === 0) {
      return;
    }

    frontmatter.components.forEach(component => {
      if (component.type !== `carousel`) {
        return;
      }

      this.carouselCount += 1;

      component.glideClass = `glide-${this.carouselCount}`;
      component.glideRef = createRef();
    });
  };

  // TODO : useful in a markdown component service
  mountCarousels = () => {
    const { frontmatter } = this.props;

    if (!frontmatter.components || frontmatter.components.length === 0) {
      return;
    }

    let defer = false;

    frontmatter.components.forEach(component => {
      if (component.type !== `carousel` || defer) {
        return;
      }

      if (!component.glideRef.current) {
        defer = true;
      } else {
        this.glides.push(
          new Glide(`.${component.glideClass}`, {
            autoplay: 3000,
            gap: 0,
            perView: 1,
            type: `carousel`
          }).mount()
        );
      }
    });

    if (defer) {
      this.glideRetryCount += 1;

      if (this.glideRetryCount > 20) {
        fancyWarning(`Glide mount retry threshold breached`);

        return;
      }

      this.timeouts.push(
        setTimeout(() => {
          this.mountCarousels();
        }, 100)
      );
    }
  };

  projectJSX = slug => {
    const { documentContext, projects } = this.props;

    let matchedProject;

    projects.forEach(project => {
      if (matchedProject) {
        return;
      }

      if (
        project.fields.slug === `/projects/${slug}` ||
        project.fields.slug === `/projects/${slug}/`
      ) {
        matchedProject = project;
      }
    });

    if (!matchedProject) {
      return <></>;
    }

    return (
      <li
        key={matchedProject.fields.slug}
        className="work-page__project relative"
      >
        <LocaleLink
          to={matchedProject.fields.slug}
          className="w-full h-full absolute top-0 right-0 bottom-0 left-0 flex items-center justify-center overflow-hidden gpu"
        >
          <div className="w-full absolute top-0 right-0 left-0 z-10 pl-12 pr-8 pt-12 flex items-center justify-between">
            <h2
              className={`b1 text-${matchedProject.frontmatter.imageFontColor}`}
            >
              — {matchedProject.frontmatter.title}
            </h2>
            <span
              className={`work-page__project__arrow transition-opacity-transform b1 text-${matchedProject.frontmatter.imageFontColor}`}
            >
              →
            </span>
          </div>

          <Plx
            className="w-full h-full relative gpu"
            parallaxData={[
              {
                start: `self`,
                duration: documentContext.windowHeight * 1.5 || 1,
                properties: [
                  {
                    startValue: documentContext.windowWidth * -0.05,
                    endValue: documentContext.windowWidth * 0.05 || 1,
                    property: `translateY`
                  }
                ]
              }
            ]}
          >
            <Img
              className="animation-fade animation-delay-2 absolute transform-center"
              fluid={matchedProject.frontmatter.image.childImageSharp.fluid}
              alt={matchedProject.frontmatter.imageAlt || `Project Banner`}
            />
          </Plx>

          <div className="work-page__project__tagline transition-opacity-transform w-full absolute bottom-0 right-0 left-0 z-10 pl-10 pr-24 pb-12 flex items-center justify-between">
            <h2
              className={`f1 text-${matchedProject.frontmatter.imageFontColor}`}
            >
              {matchedProject.frontmatter.tagline}
            </h2>
          </div>
        </LocaleLink>
      </li>
    );
  };

  splitStringToParagraphs = string => {
    const splitText = string.split(/\r?\n/);
    const jsx = [];

    splitText.forEach((row, index) => {
      const rowIndex = index;

      if (row !== ``) {
        jsx.push(
          <p key={`split-text-${rowIndex}`} className="relative block mb-4">
            {row}
          </p>
        );
      }
    });

    return jsx;
  };

  //

  render() {
    const { frontmatter, location } = this.props;
    const { authenticated, password } = this.state;

    return (
      <>
        <SEO noindex />

        {(!authenticated && (
          <Layout
            className={`exclusive-page exclusive-page--password w-full h-screen relative pb-8 bg-${frontmatter.backgroundColor} text-${frontmatter.fontColor}`}
            location={location}
          >
            <section className="relative grid pb-32">
              <h4 className="absolute top-0 right-0 pt-16 b3">
                {frontmatter.date}
              </h4>

              <h3 className="animation-appear-right grid-end-8 grid-start-5 mt-16 b1">
                — Welcome
              </h3>

              <div className="grid-end-9 grid-start-4">
                <h1 className="animation-appear-right animation-delay-1 mt-12 mb-16 f2 text-white">
                  Woah there! This area is top secret.
                </h1>

                <p className="animation-appear-right animation-delay-2 mb-4 f4">
                  <span>
                    If you’ve ended up here accidentally, then just hit back
                    (top left). Or if you’d like a reward for finding this page
                  </span>
                  <span> </span>
                  <span className="underline">click here.</span>
                </p>

                <p className="animation-appear-right animation-delay-3 mb-4 f4">
                  Alternatively, you’re here on purpose. In which case, details
                  on the right hand side to view.
                </p>
              </div>

              <div className="animation-appear-right animation-delay-5 grid-end-6 grid-start-17 relative flex flex-col justify-center">
                <input
                  className="w-full relative block py-2 f4"
                  type="password"
                  value={password}
                  onChange={this.onChange}
                  placeholder="Password"
                />

                <div className="w-full relative flex justify-end">
                  <input
                    className="mt-4 cursor-pointer b2 underline"
                    onClick={this.unlock}
                    type="submit"
                    value="Submit"
                  />
                </div>
              </div>
            </section>
          </Layout>
        )) || (
          <Layout
            className={`exclusive-page w-full relative pb-8 bg-${frontmatter.backgroundColor} text-${frontmatter.fontColor}`}
          >
            <div className="w-full relative">
              {frontmatter.backgroundColor === `white` && (
                <NavClipped color="black" />
              )}

              <section className="relative grid pb-32">
                <h3 className="absolute top-0 right-0 pt-4 b3">
                  {frontmatter.date}
                </h3>

                <h2 className="animation-appear-right grid-end-18 grid-start-5 mt-16 b1">
                  — {frontmatter.title}
                </h2>

                <div className="animation-appear-right animation-delay-1 grid-end-18 grid-start-4 mt-4 f2 text-white">
                  {this.splitStringToParagraphs(frontmatter.heading)}
                </div>

                <button
                  type="button"
                  className="animation-appear absolute bottom-0 right-0 pb-4 b3"
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: `smooth` })
                  }
                >
                  The only way is down ↓
                </button>
              </section>

              {/* // */}

              {frontmatter.components.map((component, index) => {
                const key = `section-${index}`;

                let componentJSX;

                switch (component.type) {
                  case `carousel`:
                    componentJSX = (
                      <section
                        key={key}
                        className="animation-appear-right animation-delay-2 w-full relative py-12"
                        style={{
                          backgroundColor: component.carouselBackgroundColor
                        }}
                      >
                        <div className="grid">
                          <div className="grid-end-16 grid-start-9">
                            {/* <SwiperCarousel /> */}
                          </div>
                        </div>
                      </section>
                    );

                    break;

                  case `gallery`:
                    componentJSX = (
                      <section
                        key={key}
                        className="animation-appear-right animation-delay-2 w-full relative py-12"
                        style={{
                          backgroundColor: component.galleryBackgroundColor
                        }}
                      >
                        <div className="grid">
                          <ul className="grid-end-21 grid-start-4">
                            {component.galleryImages.map(
                              (image, imageIndex) => {
                                const imagekey = `${key}-carousel-${imageIndex}`;

                                return (
                                  <li key={imagekey}>
                                    <img
                                      className="w-full block mb-6"
                                      src={`/${image}`}
                                      alt="Gallery Item"
                                    />
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </div>
                      </section>
                    );

                    break;

                  case `projects`:
                    componentJSX = (
                      <section
                        key={key}
                        className={`animation-appear-right animation-delay-2 w-full relative py-12 text-${component.projectsFontColor}`}
                        style={{
                          backgroundColor: component.projectsBackgroundColor
                        }}
                      >
                        <div className="grid">
                          <h2 className="grid-end-13 grid-start-4 mb-24 f1">
                            {component.projectsHeading}
                          </h2>

                          <div className="grid-end-20 grid-start-5 w-full relative">
                            <ul className="relative">
                              {component.projectLinks.map(projectLink =>
                                this.projectJSX(projectLink)
                              )}
                            </ul>
                          </div>
                        </div>
                      </section>
                    );

                    break;

                  case `sideStats`:
                    componentJSX = (
                      <section
                        key={key}
                        className={`animation-appear-right animation-delay-2 relative pt-12 pb-8 text-${component.sideStatsFontColor}`}
                        style={{
                          backgroundColor: component.sideStatsBackgroundColor
                        }}
                      >
                        <article className="grid">
                          {component.sideStatsSubheading && (
                            <h3 className="grid-end-4 grid-start-5 mt-1 b1">
                              — {component.sideStatsSubheading}
                            </h3>
                          )}

                          {component.sideStatsLeft && (
                            <div className="grid-end-8 grid-start-9 px-6 b3">
                              {this.splitStringToParagraphs(
                                component.sideStatsLeft
                              )}
                            </div>
                          )}

                          {component.sideStatsList && (
                            <ul className="grid-end-7 grid-start-18">
                              {component.sideStatsList.map(stat => (
                                <li key={stat} className="py-2 f4">
                                  — {stat}
                                </li>
                              ))}
                            </ul>
                          )}
                        </article>
                      </section>
                    );

                    break;

                  case `sideText`:
                    componentJSX = (
                      <section
                        key={key}
                        className={`animation-appear-right animation-delay-2 relative pt-24 pb-8 text-${component.sideTextFontColor}`}
                        style={{
                          backgroundColor: component.sideTextBackgroundColor
                        }}
                      >
                        <article className="grid">
                          {component.sideTextSubheading && (
                            <h3 className="grid-end-4 grid-start-5 b1">
                              — {component.sideTextSubheading}
                            </h3>
                          )}

                          {component.sideTextLeft && (
                            <div className="grid-end-8 grid-start-9 px-6 b3">
                              {this.splitStringToParagraphs(
                                component.sideTextLeft
                              )}
                            </div>
                          )}

                          {component.sideTextRight && (
                            <div className="grid-end-8 grid-start-17 px-6 b3 whitespace-pre-wrap">
                              {this.splitStringToParagraphs(
                                component.sideTextRight
                              )}
                            </div>
                          )}
                        </article>
                      </section>
                    );

                    break;

                  case `simpleText`:
                    componentJSX = (
                      <section
                        key={key}
                        className={`animation-appear-right animation-delay-2 relative pt-24 pb-8 text-${component.simpleTextFontColor}`}
                        style={{
                          backgroundColor: component.simpleTextBackgroundColor
                        }}
                      >
                        <article className="grid">
                          {component.simpleTextSubheading && (
                            <h3 className="grid-end-4 grid-start-5 mt-1 b1">
                              — {component.simpleTextSubheading}
                            </h3>
                          )}

                          {component.simpleTextContent && (
                            <div className="grid-end-15 grid-start-9 px-6 f4">
                              {this.splitStringToParagraphs(
                                component.simpleTextContent
                              )}
                            </div>
                          )}
                        </article>
                      </section>
                    );

                    break;

                  case `stats`:
                    componentJSX = (
                      <section
                        key={key}
                        className={`animation-appear-right animation-delay-2 relative py-24 text-${component.statsFontColor}`}
                        style={{
                          backgroundColor: component.statsBackgroundColor
                        }}
                      >
                        <article className="grid">
                          {component.statsSubheading && (
                            <h3 className="grid-end-4 grid-start-5 mt-1 b1">
                              — {component.statsSubheading}
                            </h3>
                          )}

                          {component.statsList && (
                            <ul className="grid-end-19 grid-start-4">
                              {component.statsList.map(stat => (
                                <li key={stat} className="py-2 f1">
                                  {stat}
                                </li>
                              ))}
                            </ul>
                          )}
                        </article>
                      </section>
                    );

                    break;

                  default:
                    componentJSX = <></>;
                    break;
                }

                return componentJSX;
              })}

              {/* // */}

              <section className="relative grid pb-64">
                <h3 className="grid-end-18 grid-start-5 mt-16 b1">
                  — {frontmatter.contactSubheading}
                </h3>

                <div className="grid-end-13 grid-start-4">
                  <h2 className="mt-2 f1">{frontmatter.contactHeading}</h2>
                </div>

                <h2 className="grid-end-5 grid-start-19 b2">
                  {frontmatter.contactText}
                </h2>

                <div className="grid-end-12 grid-start-13 mt-24">
                  <h3 className="f2">
                    <a href={`tel:${frontmatter.contactPhone}`}>
                      {frontmatter.contactPhoneText}
                    </a>
                  </h3>

                  {frontmatter.contactEmail && frontmatter.contactEmailText && (
                    <h3 className="f2">
                      <a
                        href={`mailto:${frontmatter.contactEmail}`}
                        className="underline"
                      >
                        {frontmatter.contactEmailText}
                      </a>
                    </h3>
                  )}
                </div>

                <button
                  type="button"
                  className="absolute bottom-0 right-0 pb-4 b3"
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: `smooth` })
                  }
                >
                  Back to top ↑
                </button>
              </section>
            </div>

            <Footer />
          </Layout>
        )}
      </>
    );
  }
}

//

const ExclusivePage = ({ data, location }) => {
  const documentContext = useContext(DocumentContext);
  const { frontmatter } = data.markdownRemark;
  const projects = [];

  data.allMarkdownRemark.edges.forEach(({ node }) => {
    projects.push(node);
  });

  return (
    <ExclusivePageComponent
      documentContext={documentContext}
      frontmatter={frontmatter}
      location={location}
      projects={projects}
    />
  );
};

export default ExclusivePage;

export const query = graphql`
  query ExclusivePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        password
        date
        heading
        backgroundColor
        fontColor

        components {
          type

          carouselItem {
            carouselImage {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }

            carouselVideoUrl
          }

          simpleImageBackgroundColor
          simpleImageSource {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }

          projectsBackgroundColor
          projectsFontColor
          projectsHeading
          projectLinks

          sideStatsBackgroundColor
          sideStatsFontColor
          sideStatsLeft
          sideStatsList
          sideStatsSubheading

          sideTextBackgroundColor
          sideTextFontColor
          sideTextLeft
          sideTextRight
          sideTextSubheading

          simpleTextBackgroundColor
          simpleTextFontColor
          simpleTextContent
          simpleTextSubheading

          statsBackgroundColor
          statsFontColor
          statsSubheading
          statsList
        }

        contactSubheading
        contactHeading
        contactText
        contactPhone
        contactPhoneText
        contactEmail
        contactEmailText
      }
    }

    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "project-page" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            tagline

            backgroundImage {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }

            backgroundImageAlt
            backgroundImageFontColor
          }
        }
      }
    }
  }
`;
